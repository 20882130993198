import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_AGENT_ADDON_CATEGORY_DETAILS,
        GET_AGENT_ADDONS_BY_CATEGORIES,
        ADD_AGENT_ADDON_CATEGORY_DETAILS,
        UPDATE_AGENT_ADDON_CATEGORY_DETAILS,
        DELETE_AGENT_ADDON_CATEGORY_DETAILS,       
        UPDATE_AGENT_ADDON_CATEGORY_PRIORITY_DETAILS
    } = URLS;
    return {
        getAddonCategoryDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_AGENT_ADDON_CATEGORY_DETAILS, body),
        getAddonsByCategoryDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_AGENT_ADDONS_BY_CATEGORIES, body),
        addAddonCategoryDetails: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_AGENT_ADDON_CATEGORY_DETAILS, body),
        updateAddonCategoryDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_AGENT_ADDON_CATEGORY_DETAILS, body),
        deleteAddonCategoryDetails: (body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', DELETE_AGENT_ADDON_CATEGORY_DETAILS, body),     
            updateAddOnCategoryPriority: (body = {}, method = 'put') =>
        performRequest(method ? method : 'put', UPDATE_AGENT_ADDON_CATEGORY_PRIORITY_DETAILS, body),
            

    }}