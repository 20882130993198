import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_AMENITY_DETAILS, ADD_AMENITY_DETAILS, UPDATE_AMENITY_DETAILS, DELETE_AMENITY_DETAILS, UPLAOD_AMENITY_IMAGE } = URLS;
    return {
        getAmenityDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_AMENITY_DETAILS, body),
        addAmenityDetails: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_AMENITY_DETAILS, body),
        updateAmenityDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_AMENITY_DETAILS, body),
        deleteAmenityDetails: (body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', DELETE_AMENITY_DETAILS, body),
        uploadAmenityImage: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', UPLAOD_AMENITY_IMAGE, body)


    }
}




