import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { 
        CUSTOM_INVOICE,
        CUSTOM_INVOICE_ID,
        GET_INVOICE_ITEMS,
        BLOCK_ROOMS,
        GET_BLOCKED_ROOMS,
        OFFLINE_PAYMENT,
        ASSIGN_PROPERTY,
        PUT_BLOCK_ROOMS,
        DATE_CHANGE,
        REMOVE_AND_BLOCK,
        GET_BOOKING_DATA
        } = URLS;
    return {
        getCustomeInvoices: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', CUSTOM_INVOICE, body),
        postCustomeInvoice: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', CUSTOM_INVOICE, body),
        getInvoice: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', CUSTOM_INVOICE_ID.replace(':_id', body._id)),
        updateInvoice: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', CUSTOM_INVOICE_ID.replace(':_id', body._id), body),
        getInvoiceItems: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_INVOICE_ITEMS, body),
        getBlockableRooms: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', BLOCK_ROOMS, body),
        blockRooms: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', BLOCK_ROOMS, body),
        getBlockedRooms: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_BLOCKED_ROOMS, body),
        postOfflinePayment: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', OFFLINE_PAYMENT.replace(':_id', body._id), body),
        postAssignProperty: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ASSIGN_PROPERTY, body),
        putBlockRooms: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', PUT_BLOCK_ROOMS, body),
        getDateChange: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', DATE_CHANGE.replace(':_id', body._id), body),
        removeAndBlockRooms: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', REMOVE_AND_BLOCK.replace(':_id', body._id), body),
        updateAssignProperty: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_BOOKING_DATA, body),
        putAssignProperty: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', ASSIGN_PROPERTY, body),
        calcelInvoice: (body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', CUSTOM_INVOICE_ID.replace(':id', body._id), body),
    }
}




