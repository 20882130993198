export const generateAppConfig = () =>{
    let exportResult = {
        appRepoLocation:"https://app-test.summerbayresort.com.my",
        backendUrl: "http://localhost:3100",
        jsReportUrl: "http://localhost:5488",
        travelAgentBackendUrl: "http://localhost:3000",
        adminRepoLocation: "http://localhost:3000",
    };
    if(process.env.REACT_APP_RESORT ==='ARCADIA-TEST'){
        exportResult.appRepoLocation = "https://sg-app.arcadiabeachresort.com.my";
        exportResult.backendUrl = "https://sg-admin-api.arcadiabeachresort.com.my";
        exportResult.jsReportUrl= "https://sg-report.arcadiabeachresort.com.my";
        exportResult.travelAgentBackendUrl =  "https://sg-business-api.arcadiabeachresort.com.my";
        exportResult.adminRepoLocation = "https://sg-admin.arcadiabeachresort.com.my";
    } 
    if(process.env.REACT_APP_RESORT ==='ARCADIA-PROD'){
        exportResult.appRepoLocation = "https://app.arcadiabeachresort.com.my";
        exportResult.backendUrl = "https://admin-api.arcadiabeachresort.com.my";
        exportResult.jsReportUrl= "https://report.arcadiabeachresort.com.my";
        exportResult.travelAgentBackendUrl =  "https://business-api.arcadiabeachresort.com.my";
        exportResult.adminRepoLocation = "https://admin.arcadiabeachresort.com.my";
    }    
    return exportResult;
}