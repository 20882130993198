import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_ADMIN_USER_LIST,
        UPDATE_ADMIN_USER_STATUS } = URLS;
    return {
        getuserListInfo: (body={}, method='get') =>
         performRequest( method ? method:'post', GET_ADMIN_USER_LIST, body ),
        updateUserStatus:(body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_ADMIN_USER_STATUS.replace(":_id", body._id),body ),
    }
}




