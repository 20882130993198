import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_ROOMNUMBER_DETAILS, ADD_ROOMNUMBER_DETAILS, UPDATE_ROOMNUMBER_DETAILS, DELETE_ROOMNUMBER_DETAILS,DUPLICATE_ROOM} = URLS;
    return {
        getRoomNUmberDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_ROOMNUMBER_DETAILS, body),
        addRoomNUmberDetails: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_ROOMNUMBER_DETAILS, body),
        updateRoomNUmberDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_ROOMNUMBER_DETAILS, body),
        deleteRoomNUmberDetails: (body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', DELETE_ROOMNUMBER_DETAILS, body),
        checkRoomNUmberUniqueness:(body = {}, method = 'get') =>
        performRequest(method ? method : 'get',`/room-number-availability/${body}`),
        roomDuplication:(body = {}, method = 'post') =>
        performRequest(method ? method : 'post',DUPLICATE_ROOM,body)


    }
}




