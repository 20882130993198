import { performRequest } from './api-handler'
import { URLS } from '../utility/urls'

export default () => {
    const { GET_TOUR_PACKAGES, GET_ROOM_TYPES, GET_ROOM_NUMBER, 
            GET_ADDITIONAL_PACKAGES, ADD_RESERVATIONS, GET_BOOKING_DETAILS,
            GET_ADDONS_CATEGORY, GET_LOCATION_START_POINTS, 
            GET_LOCATION_END_POINTS, GET_BOOKING_DATA,GET_TRAVEL_AGENT_BOOKING_DATA,
             GET_BOOKING_SUMMARY,
             GET_ROOM_TYPES_ARCADIA,
            BLOCK_ROOM_TEMP, GET_INCLUDED_ADDONS,GET_INCLUDED_ADDONS_LOCATION, ADD_OFFLINE_PAYMENT,
            UPDATE_RESERVATIONS,
            GET_BOOKING_JSON, GET_BOOKING_SETTING_DETAILS,
            GET_EXCLUDED_BOAT,
            GET_ADDITIONAL_CHARGES,
            ADD_TRANSPORTATION_NOTES,
            GET_PACKAGE_BY_ID,
            USER_UPDATE_RESERVATIONS,
            GET_TYPEOFPAX_SETTING_DETAILS,
            EDIT_BOOKING_JSON,
            ROOM_TIMER,
            UN_BLOCK_ROOM_TEMP,
            EXTEND_ROOM_TIME,
            RELEASE_ROOMS,
            UPDATE_INCLUDED_TRANSPORTATIONS,
            GET_BOOKING_USER_DETAILS,
            GET_PROMOCODE_BY_CODE,
            GET_AGENTS,
            UPDATE_DISCOUNT_AMOUNT,
            REMOVE_ROOM,
            DELETE_PAX,
            GET_LOGS,
            ADD_PAX,
            TRANS_HISTORY,
            ROOMS_AVAILABILITY
          } = URLS
    return {
        getPrmoCodeByCode: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_PROMOCODE_BY_CODE.replace(":code", body.code), body),
        updateIncludedTransportation:(body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_INCLUDED_TRANSPORTATIONS.replace(":_id", body._id), body),
        getBookingDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_TOUR_PACKAGES, body),
        getPackageInfo: (body={}, method='get',id) =>
            performRequest(method ? method:'get', GET_PACKAGE_BY_ID, body,'',id),   
        getRoomTypes: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_ROOM_TYPES, body),
        getRoomNumbers: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_ROOM_NUMBER, body),
        getAdditionalPackages:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_ADDITIONAL_PACKAGES, body),
        postReservations:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_RESERVATIONS, body),
        getBookingUserDetails:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_BOOKING_USER_DETAILS.replace(":email", body.email), body),
        getBookingDetailsByRoomWise:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_BOOKING_DETAILS, body),
        getAddOnsCategory:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_ADDONS_CATEGORY, body),
        getBookingListing:(body = {}, method = 'get',queryParam) =>
            performRequest(method ? method : 'get', `${GET_BOOKING_DATA}${queryParam}`, body),
        getUnApprovedBookingListing:(body = {}, method = 'get',queryParam) =>
            performRequest(method ? method : 'get', `${GET_TRAVEL_AGENT_BOOKING_DATA}${queryParam}`, body),
        getLocationStartPoints:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_LOCATION_START_POINTS, body),
        getLocationEndPoints:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', 
                GET_LOCATION_END_POINTS.replace(":_id", body._id).replace(":type", body.type), body),
        getBookingSummary:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', 
            UPDATE_RESERVATIONS.replace(":_id", body._id), body),
        updateBookingSummay:(body = {}, method = 'put') =>
            performRequest(method ? method : 'put', 
            UPDATE_RESERVATIONS.replace(":_id", body._id), body),
        blockRoomTemp:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', 
            BLOCK_ROOM_TEMP.replace(":_id", body._id), body),
        unBlockRoomTemp:(body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', 
                UN_BLOCK_ROOM_TEMP.replace(":_id", body._id), body),
        getIncludedAddOns:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_INCLUDED_ADDONS, body),
        getIncludedAddOnsLocation:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_INCLUDED_ADDONS_LOCATION, body),
        saveOfflinePayment:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', 
                ADD_OFFLINE_PAYMENT.replace(":_id", body._id), body),
        updateReservations:(body = {}, method = 'put') =>
            performRequest(method ? method : 'put', 
            USER_UPDATE_RESERVATIONS.replace(":_id", body._id), body),
        getPostjson:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', 
                GET_BOOKING_JSON.replace(":_id", body._id), body),
        getLeadSources:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', 
                GET_BOOKING_SETTING_DETAILS, body),
        getExcludedBoats:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', 
                GET_EXCLUDED_BOAT, body),
        getAdditionalCharges:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', 
                GET_ADDITIONAL_CHARGES, body),
          getActiveAdditionalCharges:(body = {}, method = 'get') =>
                performRequest(method ? method : 'get', 
                    `${GET_ADDITIONAL_CHARGES}?status=true`, body),
        getPaxDetails:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', 
                GET_TYPEOFPAX_SETTING_DETAILS, body),
        addTransportationNotes:(body = {}, method = 'put') =>
            performRequest(method ? method : 'put', 
                ADD_TRANSPORTATION_NOTES.replace(":_id", body._id).replace(":transId", body.transId), body),
        editBookingJson:(body = {}, method = 'put') =>
            performRequest(method ? method : 'put', 
                EDIT_BOOKING_JSON.replace(":_id", body._id), body),
        roomTimer:(body = {}, method = 'get') =>
            performRequest(method ? method : 'get', 
                ROOM_TIMER, body),
        timeExtension:(body = {}, method = 'put') =>
            performRequest(method ? method : 'put', 
                EXTEND_ROOM_TIME, body),
        releaseRooms:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', 
                RELEASE_ROOMS.replace(":resId", body._id), body),
        getAgents: (body = {}, method = 'get') => 
            performRequest(method ? method : 'get', GET_AGENTS),
        updateDiscount:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', 
                UPDATE_DISCOUNT_AMOUNT, body),
        removeRoom:(body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', 
                REMOVE_ROOM.replace(':roomId', body.roomId).replace(':reservationId', body.reservationId), body),
        deletePax:(body = {}, method = 'delete') =>
                performRequest(method ? method : 'delete', 
                    DELETE_PAX.replace(':roomId', body.roomId).replace(':reservationId', body.reservationId), body),
        getLogs: (body = {}, method = 'get') => 
            performRequest(method ? method : 'get', GET_LOGS.replace(':_id', body._id)),
        addPax:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', 
                ADD_PAX.replace(':_id',body._id).replace(':roomId', body.roomId), body),
    getTransactionHistory:(body = {}, method = 'get',id,params) =>
                performRequest(method ? method : 'get', 
                    TRANS_HISTORY.replace(":id", id), body),
        getRoomsAvailability: (body={}, method='post',) =>
            performRequest(method ? method:'post', ROOMS_AVAILABILITY, body),
        getRoomTypesArcadia: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_ROOM_TYPES_ARCADIA, body),
    }
}