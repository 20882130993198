import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const {
        GET_FACILITY_DETAILS,
        ADD_FACILITY_DETAILS,
        UPDATE_FACILITY_DETAILS,
        DELETE_FACILITY_DETAILS } = URLS;
    return {
        getFacilityDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_FACILITY_DETAILS, body),
        addFacilityDetails: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_FACILITY_DETAILS, body),
        updateFacilityDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_FACILITY_DETAILS, body),
        deleteFacilityDetails: (body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', DELETE_FACILITY_DETAILS, body)

    }
}




