import * as Types from '../../actions/type'
const initialState = {
    loading: false,
    loaded: false,
    data: [],
    params: null,
    allData: [],
}

const dummyBeds = {beds: [],
    page:1,
    pages:1,
    limit: 10,
    total: 0,
    allData: []
}
const getIndex = (arr, arr2, arr3, params = {}) => {
    if (arr2.length > 0) {
      let startIndex = arr.findIndex(i => i.id === arr2[0].id) + 1
      let endIndex = arr.findIndex(i => i.id === arr2[arr2.length - 1].id) + 1
      let finalArr = [startIndex, endIndex]
      return (arr3 = finalArr)
    } else {
      let finalArr = [params.page, params.pages]
      return (arr3 = finalArr)
    }
}


export const bedsDetails = ( state=initialState, action) =>{
    switch (action.type){
        case Types.GET_BED_DETAILS:
            return { ...state, loading: true };
        case Types.GET_BED_DETAILS_SUCCESS: 
        let newData = {
        }
        if(Object.keys(action.payload).length > 0){
            newData = { ...action.payload};
            newData.allData = [...action.payload.beds]
        }else{
            newData = {...dummyBeds}
        }
        newData.sortIndex = getIndex(newData.allData, state.data, state.sortIndex, {...newData})
        return { ...state, loading: false, loaded: true, payload: newData }
        case Types.GET_BED_DETAILS_FAILURE:
            return { ...state, loading: false, loaded: false, error: action.payload }
        case Types.ADD_BED_DATA: 
        let updatedData = { ...state.payload}
        updatedData.beds.unshift({...action.payload});
        updatedData.allData = [...updatedData.beds];
        updatedData.total = updatedData.beds.length;
        updatedData.sortIndex = getIndex(updatedData.beds, 
                                        updatedData.allData, 
                                        state.payload.sortIndex, 
                                        {...updatedData})
        return { ...state, loading: false, loaded: true, payload: updatedData}
        case Types.UPDATE_BED_DATA:
            let updatingData = { ...state.payload}           
            let newBeds = updatingData.beds.map((item) =>{
                if(item._id === action.payload._id){
                    item = {...action.payload}
                }
                return item
            })
            updatingData.beds = [...newBeds];
            return {
                ...state, loading: false, loaded: true, payload: {...updatingData}
            }
        case Types.DELETE_BED_DATA:
                let deleteData = { ...state.payload}
                let deleteingBeds = deleteData.beds.filter((item) =>{
                    return item._id !== action.payload._id;
                })
                deleteData.beds = [...deleteingBeds];
                deleteData.allData = [...deleteingBeds];
                deleteData.total = deleteData.beds.length;
                deleteData.sortIndex = getIndex(deleteData.beds, 
                                                deleteData.allData, 
                                                state.payload.sortIndex, 
                                                {...deleteData})
                return {
                    ...state, loading: false, loaded: true, payload: {...deleteData}
                }
        default: 
            return state
    }
}



