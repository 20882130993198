import * as Types from '../../actions/type'
const initialState = {
    loading: false,
    loaded: false,
    data: [],
    params: null,
    allData: []
}

const getIndex = (arr, arr2, arr3, params = {}) => {
    if (arr2.length > 0) {
      let startIndex = arr.findIndex(i => i.id === arr2[0].id) + 1
      let endIndex = arr.findIndex(i => i.id === arr2[arr2.length - 1].id) + 1
      let finalArr = [startIndex, endIndex]
      return (arr3 = finalArr)
    } else {
      let finalArr = [params.page, params.pages]
      return (arr3 = finalArr)
    }
}

export const maintenanceDetails = ( state=initialState, action) =>{
    switch (action.type){
        case Types.GET_MAINTANCE_INFO:
            return { ...state, loading: true };
        case Types.GET_MAINTANCE_SUCCESS: 
            let newData = { ...action.payload}
            newData.allData = action.payload.hasOwnProperty('maintanence')? [...action.payload.maintanence]: [];
            newData.sortIndex = action.payload.hasOwnProperty('maintanence')? getIndex(action.payload.maintanence, state.data, state.sortIndex, {...action.payload}):[];
                return { ...state, loading: false, loaded: true, payload: action.payload.hasOwnProperty('maintanence')? newData: {} }
        case Types.GET_MAINTANCE_FAILURE:
                return { ...state, loading: false, loaded: false, error: action.payload }        
        default: 
            return state
    }
}



