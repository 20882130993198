import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_ADDON_CATEGORY_DETAILS,
        GET_ADDONS_BY_CATEGORIES,
        ADD_ADDON_CATEGORY_DETAILS,
        UPDATE_ADDON_CATEGORY_DETAILS,
        DELETE_ADDON_CATEGORY_DETAILS,
        GET_ADDON_CATEGORY_SUBCATEGORY_DETAILS,
        SEARCH_BY_ADDON_CATEGORY,
        UPDATE_ADDON_CATEGORY_PRIORITY_DETAILS
    } = URLS;
    return {
        getAddonCategoryDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_ADDON_CATEGORY_DETAILS, body),
        getAddonsByCategoryDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_ADDONS_BY_CATEGORIES, body),
        addAddonCategoryDetails: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_ADDON_CATEGORY_DETAILS, body),
        updateAddonCategoryDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_ADDON_CATEGORY_DETAILS, body),
        deleteAddonCategoryDetails: (body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', DELETE_ADDON_CATEGORY_DETAILS, body),
        getAddonCategorySubcategoryDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', `/addon-category/${body}/addon-sub-categories`),
        getCategoryBySearch: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', `/addon/by-category/${body}`),
            updateAddOnCategoryPriority: (body = {}, method = 'put') =>
        performRequest(method ? method : 'put', UPDATE_ADDON_CATEGORY_PRIORITY_DETAILS, body),
            

    }}