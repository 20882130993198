import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { BOAT_SCHEDULE_DESCRIPTION } = URLS;
    return {
     getBoatScheduleDescription: (body = {}, method = 'get') =>
        performRequest(method ? method : 'get', BOAT_SCHEDULE_DESCRIPTION, body),
     addBoatScheduleDescription: (body = {}, method = 'post') =>
        performRequest(method ? method : 'post', BOAT_SCHEDULE_DESCRIPTION, body),
     updateBoatScheduleDescription: (body = {}, method = 'put',id) =>
        performRequest(method ? method : 'put', `${BOAT_SCHEDULE_DESCRIPTION}?_id=${body._id}`, body),   
    }
}