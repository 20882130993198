import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_ROLE_DETAILS,ADD_ROLE_DETAILS,UPDATE_ROLE_DETAILS,DELETE_ROLE_DETAILS } = URLS;
    return {
        getRoleDetails: (body={}, method='get') =>
         performRequest( method ? method:'post', GET_ROLE_DETAILS, body ),
        addRoleDetails: (body={}, method='post') =>
         performRequest( method ? method:'post', ADD_ROLE_DETAILS, body ),
        updateRoleDetails: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_ROLE_DETAILS, body ),
        deleteRoleDetails: (body={}, method='delete') =>
         performRequest( method ? method:'delete', DELETE_ROLE_DETAILS, body )

    }
}




