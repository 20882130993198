import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_PAYMENT_REMINDER,
        GET_EXPIRED_INVOICE,
        GET_DASBOARD_GRAPH,
        GET_DASHBOARD_PAYTORESORT,
        GET_DASHBOARD_TODAYPICKUP,
        GET_DASHBOARD_CARDCOUNT,
        GET_DASHBOARD_CURRENTLIST,
        GET_DASHBOARD_UNCONFIRMED_PAY,
        GET_DASHBOARD_CARDS_INFO,
        GET_DASHBOARD_MONTHLY_REPORT,
        GET_DAILY_REPORT_RESRV,
        GET_DASHBOARD_MONTHLY_SALES_REPORT,
        GET_DAILY_SALES_REPORT,
        GET_OCCUPENCY_INFO,
        GET_AGENT_ADDONS,
        UPDATE_AGENT_ADDONS,
        GET_YEARLY_REPORT_INFO,
        GET_COUNTRY_REPORT_INFO,
        GET_AGENT_EXIPIRED_PAYMENT,
        GET_AGENT_PARTIAL_PAYMENT,
        GET_AGENT_UNCONFIRMED,
        GET_ADDON_YEARLY_REPORT,
        GET_AGENT_REPORT_INFO,
        GET_TOP_NIGHT_INFO,
        GET_BOOKING_FROM_INFO,
        GET_TOP_PACKAGE_INFO,
        GET_REMAINDERS_DASH,
        GET_AGENTS_PAYOUT,
        GET_AGENT_PAYOUT_COMMISSION,
        PAYOUT_COMMISIONS,
        GET_UNAPPROVED_BOOKINGS,
        WALLET_PAYOUTS,
        GET_AGENT_COMMISION_DETAILS,
        GET_MONTHLY_REPORT_STATE_SPLITUP,
        GET_PORTAL_BOOKING_SALES,
        GET_GPA_INFO,
    } = URLS;
    return {
        getPaymentReminder: (body={}, method='get',date) =>
         performRequest( method ? method:'get', GET_PAYMENT_REMINDER, body,"",null,date ),
        getCurrentList: (body={}, method='get',date) =>
         performRequest( method ? method:'get', GET_DASHBOARD_CURRENTLIST, body,"",null,date ),
         getCurrentListCheckIn: (body={}, method='get',date) =>
         performRequest( method ? method:'get', GET_DASHBOARD_CARDS_INFO+'?type=arrival', body,"",null,date ),
         getCurrentListCheckOut: (body={}, method='get',date) =>
         performRequest( method ? method:'get', GET_DASHBOARD_CARDS_INFO+'?type=dept', body,"",null,date ),
        getExpiredInvoice: (body={}, method='get',date) =>
         performRequest( method ? method:'get', GET_EXPIRED_INVOICE, body,"",null,date ),
        getGraphInfo: (body={}, method='get',dateUrl) =>
         performRequest( method ? method:'get', GET_DASBOARD_GRAPH+dateUrl, body ),
        getPayToResort: (body={}, method='get',date) =>
         performRequest( method ? method:'get', GET_DASHBOARD_PAYTORESORT, body,"",null,date ),
        getTodayPickup: (body={}, method='get',date) =>
         performRequest( method ? method:'get', GET_DASHBOARD_TODAYPICKUP, body,"",null,date ),
        getCardInfo: (body={}, method='get',date) =>
         performRequest( method ? method:'get', GET_DASHBOARD_CARDCOUNT, body,"",null,date ),
        getTodayBookingInfo: (body={}, method='get',date,dateUrl) =>
         performRequest( method ? method:'get', GET_DASHBOARD_CARDCOUNT+dateUrl, body,"",null,date),
        getUnconfirmedPay: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_DASHBOARD_UNCONFIRMED_PAY, body ),
        getDashCardsInfo: (body={}, method='get',date,url) =>
         performRequest( method ? method:'get', url ? url:GET_DASHBOARD_CARDS_INFO, body, "", null ,date ),
        getDashMonthlyInfo: (body={}, method='get',dateUrl) =>
         performRequest( method ? method:'get', GET_DASHBOARD_MONTHLY_REPORT+dateUrl, body), 
        getDashMonthlySalesInfo: (body={}, method='get',dateUrl) =>
         performRequest( method ? method:'get', GET_DASHBOARD_MONTHLY_SALES_REPORT+dateUrl, body),
        getDashDailySalesInfo: (body={}, method='get',dateUrl) =>
         performRequest( method ? method:'get', GET_DAILY_SALES_REPORT+dateUrl, body),
         getDailyReservation: (body={}, method='get',dateUrl) =>
         performRequest( method ? method:'get', GET_DAILY_REPORT_RESRV+dateUrl, body),
        getDashOccupancyInfo: (body={}, method='get',dateUrl) =>
         performRequest( method ? method:'get', GET_OCCUPENCY_INFO+dateUrl, body),
        getAgentAddons: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_AGENT_ADDONS, body),
        updateAgentAddons: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_AGENT_ADDONS, body ),
        getYearlyReport: (body={}, method='get',date) =>
         performRequest( method ? method:'get', GET_YEARLY_REPORT_INFO, body,"",null,date ),
        getDashCountry: (body={}, method='get',extUrl) =>
         performRequest( method ? method:'get', GET_COUNTRY_REPORT_INFO+extUrl, body), 
        getAgentExpired: (body={}, method='get',date) =>
            performRequest( method ? method:'get', GET_AGENT_EXIPIRED_PAYMENT, body,"",null,date ),
        getAgentPartial: (body={}, method='get',date) =>
            performRequest( method ? method:'get', GET_AGENT_PARTIAL_PAYMENT, body,"",null,date ),
        getAgentUnconfirmed: (body={}, method='get') =>
            performRequest( method ? method:'get', GET_AGENT_UNCONFIRMED, body),
        getAddonYearlyReport: (body={}, method='get',date) =>
            performRequest( method ? method:'get', GET_ADDON_YEARLY_REPORT, body,"",null,date),
        getAgentReport: (body={}, method='get',year) =>
            performRequest( method ? method:'get', GET_AGENT_REPORT_INFO.replace(":year", year), body),
        getTopNightReport: (body={}, method='get',year) =>
            performRequest( method ? method:'get', GET_TOP_NIGHT_INFO.replace(":year", year), body),
        getBookingFromReport: (body={}, method='get',year) =>
            performRequest( method ? method:'get', GET_BOOKING_FROM_INFO.replace(":year", year), body),
        getTopPackageReport: (body={}, method='get',year) =>
            performRequest( method ? method:'get', GET_TOP_PACKAGE_INFO.replace(":year", year), body),
        getReminderDash: (body={}, method='get',dateqr) =>
            performRequest( method ? method:'get', GET_REMAINDERS_DASH+dateqr),
        getAgentPayouts: (body={}, method='get',year) =>
            performRequest( method ? method:'get', GET_AGENTS_PAYOUT.replace(":year", year), body),
        getAgentPayoutComission: (body={}, method='get',year) =>
            performRequest( method ? method:'get', GET_AGENT_PAYOUT_COMMISSION.replace(":year", year).replace(':agentId', body.agentId), body),
        PayAgentPayoutComission: (body={}, method='post') =>
            performRequest( method ? method:'post', PAYOUT_COMMISIONS, body),
        getPayoutHistory: (body={}, method='get') =>
            performRequest( method ? method:'get', PAYOUT_COMMISIONS, body),
        getWalletPayout: (body={}, method='get') =>
            performRequest( method ? method:'get', WALLET_PAYOUTS, body),
        updateWalletPayout: (body={}, method='put') =>
            performRequest( method ? method:'put', WALLET_PAYOUTS, body ), 
        getUnapprovedBookings: (body = {}, method = 'get') => 
            performRequest( method ? method:'get', GET_UNAPPROVED_BOOKINGS, body),
        getAgentCommisionDetails: (body={}, method='get',year) =>
            performRequest( method ? method:'get', GET_AGENT_COMMISION_DETAILS.replace(":year", year), body),
        getBookingCommisionDetails: (body={}, method='get',year,query) =>
            performRequest( method ? method:'get', GET_AGENT_COMMISION_DETAILS.replace(":year", year)+query, body),
        getMonthlyReportStateSplitup: (body={}, method='get',year,month) =>
            performRequest( method ? method:'get', GET_MONTHLY_REPORT_STATE_SPLITUP.replace(":year", year).replace(":month", month), body),
        getBookingStateSplitup: (body={}, method='get',param) =>
            performRequest( method ? method:'get', GET_PORTAL_BOOKING_SALES.replace(":year", '').replace(":month", param), body),
        getGpaMonthlyInfo: (dateUrl) =>
            performRequest('get', GET_GPA_INFO+dateUrl), 
    }
}