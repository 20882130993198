import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_DEPARTMENT_DETAILS,ADD_DEPARTMENT_DETAILS,UPDATE_DEPARTMENT_DETAILS,DELETE_DEPARTMENT_DETAILS } = URLS;
    return {
        getDepartmentDetails: (body={}, method='get') =>
         performRequest( method ? method:'post', GET_DEPARTMENT_DETAILS, body ),
        addDepartmentDetails: (body={}, method='post') =>
         performRequest( method ? method:'post', ADD_DEPARTMENT_DETAILS, body ),
        updateDepartmentDetails: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_DEPARTMENT_DETAILS, body ),
        deleteDepartmentDetails: (body={}, method='delete') =>
         performRequest( method ? method:'delete', DELETE_DEPARTMENT_DETAILS, body )

    }
}




