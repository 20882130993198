import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'
import moment from "moment"

export default () => {
    const { GET_RESERVATION_INFO,
        SET_RESERVATION_PAYMENT_STATUS,
        GET_PAYMENT_STATUS,
        GET_TRANS_REPORT,
        UPDATE_GEUST_RESERVATION_INFO,
        GET_MARINE_REPORT,
        GET_AGENT_RESERVATION_INFO,
        CONFIRM_AGENT_PAYMENT,
        AGENT_BOOKING_APPROVAL,
        REJECT_AGENT_BOOKING,
        CHECK_CUSTOMER_REGISTER,
        GET_DAILY_REPORT,
        GET_FUND_REPORT,
        REFUND_RESERVATION_REJECT,
        TRANSPORT_UPDATE_PAX,
        SEND_PAYMENT_REJECT_MAIL,
        DAILY_BOOK_DATE_REPORT,
        REFUND_CUSTOM_RESERVATION_REJECT,
        SNORKELING
    } = URLS;
    return {
        updateNotes: (body = {}, method = 'put', id) =>
            performRequest(method ? method : 'put', GET_RESERVATION_INFO.replace(":_id", body._id), body),
        getReservationDetails: (body = {}, method = 'get', id) =>
            performRequest(method ? method : 'get', GET_RESERVATION_INFO, body, '', id),
        getAgentReservationDetails: (body = {}, method = 'get', id) =>
            performRequest(method ? method : 'get', GET_AGENT_RESERVATION_INFO, body, '', id),
        changePaymentStatus: (body = {}, method = 'put', id, paymentId) =>
            performRequest(method ? method : 'put', SET_RESERVATION_PAYMENT_STATUS, body, '', id),
        changeReservationStatus: (body = {}, method = 'put', id) =>
            performRequest(method ? method : 'put', GET_RESERVATION_INFO, body, '', id),
        updateRefundAmount: (body = {}, method = 'put', id, rejectRefund) =>
            performRequest(method ? method : 'put', rejectRefund ? REFUND_RESERVATION_REJECT : GET_RESERVATION_INFO, body, '', id),
        updateCustomRefundAmount: (body = {}, method = 'put', id, rejectRefund) =>
            performRequest(method ? method : 'put', REFUND_CUSTOM_RESERVATION_REJECT, body, '', id),
        extendBookingDate: (body = {}, method = 'put', id) =>
            performRequest(method ? method : 'put', GET_RESERVATION_INFO, body),
        guistListChange: (body = {}, method = 'put', id) =>
            performRequest(method ? method : 'put', UPDATE_GEUST_RESERVATION_INFO, body),
        getPaymentStatus: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_PAYMENT_STATUS.replace(":_id", body._id), body),
        getTransReport: (body = {}, method = 'get', date) =>
            performRequest(method ? method : 'get', GET_TRANS_REPORT, body, "", "", date),
        getDailyReport: (body = {}, method = 'get', date) =>
            performRequest(method ? method : 'get', GET_DAILY_REPORT, body, "", "", date),
        getFundReport: (body = {}, method = 'get', year, type) =>
            performRequest(method ? method : 'get', GET_FUND_REPORT.replace(":year", year).replace(":type", type), body, "", ""),
        getMarineReport: (body = {}, method = 'get', date) =>
            performRequest(method ? method : 'get', GET_MARINE_REPORT, body, "", "", date),
        confirmAgentPayment: (body = {}, method = 'put', id, paymentId) =>
            performRequest(method ? method : 'put', CONFIRM_AGENT_PAYMENT.replace(":_id", body._id).replace(":payId", body.payId), body, '', id),
        approveAgentBooking: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', AGENT_BOOKING_APPROVAL.replace(":_id", body._id), body),
        rejectAgentBooking: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', REJECT_AGENT_BOOKING.replace(":_id", body._id), body),
        checkCustomerDetails: (body = {}, method = 'get', id) =>
            performRequest(method ? method : 'get', CHECK_CUSTOMER_REGISTER, body, '', id),
        updateTransportPax: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', TRANSPORT_UPDATE_PAX.replace(':_id', body.reservationId).replace(':transId', body.transId), body),
        sendPaymentRejectMail: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', SEND_PAYMENT_REJECT_MAIL, body),
        getDailyDateReport: (body = {}, method = 'get', query) =>
            performRequest(method ? method : 'get', DAILY_BOOK_DATE_REPORT + query),
        getSnorkeling: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', SNORKELING, body),
    }
}