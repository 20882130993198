import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_AGENT_FILES, UPLOAD_AGENT_FILES,
        DELETE_AGENT_FILES,
        UPDATE_AGENT_FILES,
        GET_AGENT_IMAGES,
        UPLOAD_AGENT_IMAGES,
        UPDATE_AGENT_IMAGES,
        DELETE_AGENT_IMAGES
    } = URLS;
    return {
        getAgentFiles: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_AGENT_FILES, body),
        addAgentProfile: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', UPLOAD_AGENT_FILES, body),
        updateAgentFiles: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_AGENT_FILES, body),
        deleteAgentFiles: (body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', DELETE_AGENT_FILES, body),
        getAgentImages: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_AGENT_IMAGES, body),
        addAgentImages: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', UPLOAD_AGENT_IMAGES, body),
        updateAgentImages: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_AGENT_IMAGES, body),
        updateAgentStatus: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', `${UPLOAD_AGENT_IMAGES}/?_id=${body._id}&status=${body.status}`, body),
        deleteAgentImages: (body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', DELETE_AGENT_IMAGES, body),

    }
}