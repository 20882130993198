import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_OR_ADD_FEEDBACK_CATEGORY,
        UPDATE_OR_DELETE_FEEDBACK_CATEGORY,
        GET_OR_ADD_FEEDBACK_QUESTION,
        UPDATE_OR_DELETE_FEEDBACK_QUESTION,
        GET_CUSTOMER_FEEDBACK } = URLS;
    return {
     getFeedBackCategory: (body = {}, method = 'get') =>
        performRequest(method ? method : 'post', GET_OR_ADD_FEEDBACK_CATEGORY, body),
     addFeedBackCategory: (body = {}, method = 'post') =>
        performRequest(method ? method : 'post', GET_OR_ADD_FEEDBACK_CATEGORY, body),
     updateFeedBackCategory: (body = {}, method = 'put',id) =>
        performRequest(method ? method : 'put', UPDATE_OR_DELETE_FEEDBACK_CATEGORY.replace(":id", id), body),
     deleteFeedBackCategory: (body = {}, method = 'delete',id) =>
        performRequest(method ? method : 'delete', UPDATE_OR_DELETE_FEEDBACK_CATEGORY.replace(":id", id), body),
     getFeedBackQuestion: (body = {}, method = 'get') =>
        performRequest(method ? method : 'post', GET_OR_ADD_FEEDBACK_QUESTION, body),
     addFeedBackQuestion: (body = {}, method = 'post') =>
        performRequest(method ? method : 'post', GET_OR_ADD_FEEDBACK_QUESTION, body),
     updateFeedBackQuestion: (body = {}, method = 'put',id) =>
        performRequest(method ? method : 'put', UPDATE_OR_DELETE_FEEDBACK_QUESTION.replace(":id", id), body),
     deleteFeedBackQuestion: (body = {}, method = 'delete',id) =>
        performRequest(method ? method : 'delete', UPDATE_OR_DELETE_FEEDBACK_QUESTION.replace(":id", id), body),
      getCustomerFeedBack: (body = {}, method = 'get') =>
        performRequest(method ? method : 'post', GET_CUSTOMER_FEEDBACK, body),
      getIndvCustomerFeedBack: (body = {}, method = 'get',id) =>
        performRequest(method ? method : 'post', GET_CUSTOMER_FEEDBACK+`/${id}`, body),
      updateFeedback: (body = {}, method = 'put',id) => 
         performRequest(method ? method : 'put', GET_CUSTOMER_FEEDBACK+`/${id}`,body)
        
    }
}