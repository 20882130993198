import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_USER_DETAILS,ADD_USER_DETAILS,UPDATE_USER_DETAILS,
        DELETE_USER_DETAILS, GET_USER_DETAILS_BY_PROOF, REJOIN_USER, RESIGN_USER, SEND_OTP, VERIFY_OTP } = URLS;
    return {
        getUserDetails: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_USER_DETAILS, body ),
        addUserDetails: (body={}, method='post') =>
         performRequest( method ? method:'post', ADD_USER_DETAILS, body ),
        updateUserDetails: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_USER_DETAILS, body ),
        deleteUserDetails: (body={}, method='get') =>
         performRequest( method ? method:'post', DELETE_USER_DETAILS, body ),
        getUserDetailsByProof:(body={}, method='get') =>
         performRequest( method ? method:'get', GET_USER_DETAILS_BY_PROOF+ body.proofType+"/"+body.proofNumber, body ),
        rejoinUser: (body={}, method='post') =>
         performRequest( method ? method:'post', REJOIN_USER, body ),
        resignUser: (body={}, method='post') =>
         performRequest( method ? method:'post', RESIGN_USER, body ),
        sendOtp: (body={}, method='post') =>
         performRequest( method ? method:'post', SEND_OTP, body ),
        verifyOtp: (body={}, method='post') =>
         performRequest( method ? method:'post', VERIFY_OTP, body ),
    }
}




