import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_CATEGORY_DETAILS, 
            ADD_CATEGORY_DETAILS,
            UPDATE_CATEGORY_DETAILS,
            DELETE_CATEGORY_DETAILS } = URLS;
    return {
        getCategoryDetails: (body={}, method='get') =>
         performRequest( method ? method:'post', GET_CATEGORY_DETAILS, body ),
        addCategoryDetails: (body={}, method='post') =>
         performRequest( method ? method:'post', ADD_CATEGORY_DETAILS, body ),
        updateCategoryDetails: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_CATEGORY_DETAILS, body ),
        deleteCategoryDetails: (body={}, method='delete') =>
         performRequest( method ? method:'delete', DELETE_CATEGORY_DETAILS, body )

    }
}