import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_SURCHARGES_DETAILS,
        ADD_SURCHARGES_DETAILS,
        UPDATE_SURCHARGES_DETAILS,
        DELETE_SURCHARGES_DETAILS,
        GET_SURCHARGE_CALENDER,
        ADD_SURCHARGE_CALENDER,
        SURCHARGE_DESCRIPTION
    } = URLS;
    return {
        getSurchargesDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_SURCHARGES_DETAILS, body),
        addSurchargesDetails: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_SURCHARGES_DETAILS, body),
        updateSurchargesDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_SURCHARGES_DETAILS, body),
        deleteSurchargesDetails: (body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', DELETE_SURCHARGES_DETAILS, body),

        getSurchargeCalenderDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_SURCHARGE_CALENDER, body),

        addSurchargeCalendarDetails: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_SURCHARGE_CALENDER, body),
        getSurchargeDescription: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', SURCHARGE_DESCRIPTION),
        postSurchargeDescription: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', SURCHARGE_DESCRIPTION, body),
        updateSurchargeDescription: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', `${SURCHARGE_DESCRIPTION}?_id=${body._id}`, body),
    }
}




