import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_COUNTRYWISE_PAX_LIST} = URLS;
    return {
        getreportDetails: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_COUNTRYWISE_PAX_LIST, body ),      
    }
}




