import logo from "./logo/logo.png";


let applicationName = "Arcadia Digital Intelligence Platform (ADIP)"

let siteName = "https://travoxis.com"

let FooterName = "Travoxios (1165017-V)"

// if(process.env.REACT_APP_PRODUCTION_MODE !== "STAGE"){
//     applicationName = "Summerbay Digital Intelligence Platform (SBDIP)"
//     logo = summerbaylogo
//     FooterName = " Travoxis Technology SDN BHD"
// }

export {
    logo,
    applicationName,
    siteName,
    FooterName
}
