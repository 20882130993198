import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_DIVINGDEPARTMENT_SETTING_DETAILS, ADD_DIVINGDEPARTMENT_SETTING_DETAILS, UPDATE_DIVINGDEPARTMENT_SETTING_DETAILS, DELETE_DIVINGDEPARTMENT_SETTING_DETAILS, GET_STAFF_DETAILS, DIVING_STAFF } = URLS;
    return {
        getDivingDepartmentDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_DIVINGDEPARTMENT_SETTING_DETAILS, body),
        addDivingDepartmentDetails: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_DIVINGDEPARTMENT_SETTING_DETAILS, body),
        updateDivingDepartmentDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_DIVINGDEPARTMENT_SETTING_DETAILS, body),
        deleteDivingDepartmentDetails: (body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', DELETE_DIVINGDEPARTMENT_SETTING_DETAILS, body),
        getUserDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_STAFF_DETAILS, body),
        getDivingStaffDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', DIVING_STAFF, body)

    }
}




