import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_LEAVE_LIST,
        UPDATE_LEAVE_LIST,LEAVE_APPROVE } = URLS;
    return {
        getLeaveDetails: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_LEAVE_LIST, body ),
        applyLeaveApi: (body={}, method='post') =>
         performRequest( method ? method:'post', GET_LEAVE_LIST, body ),
        updateLeaveDetails: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_LEAVE_LIST, body ), 
        deleteLeaveDetails: (body={}, method='delete') =>
         performRequest( method ? method:'delete', UPDATE_LEAVE_LIST, body ),
        approveLeave: (body={}, method='put') =>
         performRequest( method ? method:'put', LEAVE_APPROVE,body),
    }
}




