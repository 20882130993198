import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_PERMISSION_DETAILS, 
            GET_APP_MODULES_DETAILS,
            ADD_PERMISSION_DETAILS,           
         } = URLS;
    return {
        getPermissionDetails: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_PERMISSION_DETAILS, body ),
        getAppModulesDetails: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_APP_MODULES_DETAILS, body ),
        addPermisssionDetails: (body={}, method='post') =>
         performRequest( method ? method:'post', ADD_PERMISSION_DETAILS, body ),       
    }
}