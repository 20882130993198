import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_TAXES_DETAILS, 
            ADD_TAXES_DETAILS,
            UPDATE_TAXES_DETAILS,
            DELETE_TAXES_DETAILS,
            GET_COMPANIES_DETAILS } = URLS;
    return {
        getTaxesDetails: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_TAXES_DETAILS, body ),
        addTaxesDetails: (body={}, method='post') =>
         performRequest( method ? method:'post', ADD_TAXES_DETAILS, body ),
        updateTaxesDetails: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_TAXES_DETAILS, body ),
        deleteTaxesDetails: (body={}, method='delete') =>
         performRequest( method ? method:'delete', DELETE_TAXES_DETAILS, body ),
        getcompaniesDetails: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_COMPANIES_DETAILS, body ),

    }
}