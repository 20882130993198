import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_BED_DETAILS,ADD_BED_DETAILS, 
            UPDATE_BED_DETAILS,DELETE_BED_DETAILS,
            CHANGE_STATUS_BED_DETAILS, DELETE_BED_TYPES_DETAILS } = URLS;
    return {
        getBedDetails: (body={}, method='get') =>
         performRequest( method ? method:'post', GET_BED_DETAILS, body ),
        addBedDetails: (body={}, method='post') =>
         performRequest( method ? method:'post', ADD_BED_DETAILS, body ),
        updateBedDetails: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_BED_DETAILS, body ),
        deleteBedDetails: (body={}, method='delete') =>
         performRequest( method ? method:'delete', DELETE_BED_DETAILS, body ),
        changeStatusBedDetails: (body={}, method='put') =>
         performRequest( method ? method:'put', CHANGE_STATUS_BED_DETAILS, body ),
        deleteBedTypes:(body={}, method='delete') =>
         performRequest( method ? method:'delete', DELETE_BED_TYPES_DETAILS.replace(":_id", body._id).replace(":_comboId", body.comboId), body ),

    }
}




