import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_ONLINE_PAYMENT,
        GET_ONLINE_PAYMENT_WITH_ID } = URLS;
    return {
        
        getOnlinePayment: (body={}, method='get')=>
            performRequest(method?method:'get',GET_ONLINE_PAYMENT, body),
        getOnlinePaymentPerPeriod: (body = {}, method = 'get',url) =>
            performRequest(method ? method : 'get',url,body),
    }
}