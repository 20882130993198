import { buildingsService } from '../../../service'
import * as Types from "../type"

const initialize = () => ({
    type: Types.GET_BUILDING_DETAILS
})

const messageSuccess = data => ({
    type: Types.GET_BUILDING_DETAILS_SUCCESS,
    payload: {
        ...data
    }
})

const messageFailure = error => ({
    type: Types.GET_BUILDING_DETAILS_FAILURE,
    payload: {
        error
    }
})

export function buildingsDetailsService(data) {
    console.log(data)
    return dispatch => {
        dispatch(initialize())
        buildingsService().getBuildingsDetails(data)
            .then(res => {
                // console.log(res)
                dispatch(messageSuccess(res.data.data))
            })
            .catch(err => {
                dispatch(messageFailure(err))
            })
    }
}

export function addNewData(data) {
    return dispatch => dispatch({ type: Types.ADD_BUILDING_DATA, payload: data })
}

export function updateData(data) {
    return dispatch => dispatch({ type: Types.UPDATE_BUILDING_DATA, payload: data })
}

export function deleteData(data) {
    return dispatch => dispatch({ type: Types.DELETE_BUILDING_DATA, payload: data })
}