import { combineReducers } from "redux"
import calenderReducer from "./calendar/"
import emailReducer from "./email/"
import chatReducer from "./chat/"
import todoReducer from "./todo/"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import dataList from "./data-list/"
import userReducers from "./users"
import roleReducers from "./roles"
import departmentReducers from "./departments"
import buildingsReducers from "./buildings"
import bedReducers from "./beds"
import amenityReducers from"./amenities"
import roomTypeReducers from "./roomTypes"
import roomNumberReducers from "./roomNumber"
import companyReducers from "./companies"
import categoryReducers from "./category"
import tripcostsReducers from "./tripCosts"
import addonsCategoryReducers from "./addonsCategory"
import agentAddonsCategoryReducers from "./agentAddonsCategory"
import addonsSubCategoryReducers from "./addonsSubCategory"
import vehicleReducers from "./vehicles"
import divingCourseReducers from "./divingcourse"
import divingDepartmentReducers from "./divingdepartment"
import locationsReducers from "./locations"
import mealsReducers from "./meals"
import addOnReducers from "./addons"
import facilityReducers from "./facility"
import resturantsReducers from "./resturants"
import surchargeReducers from "./surcharges"
import taxesReducers from "./taxes"
import travelAgentsDetailsReducers from "./travelAgents"
import packageReducers from "./package"
import currencyReducers from "./currency"
import additionalChargeReducers from "./additionalCharges"
import bookingEnquiryReducers from "./bookingEnquiry"
import bookingSettingReducers from "./bookingSetting"
import travelAgentsRegistrationDetailsReducers from "./travelagentregistration"
import bookingListingReducers from "./bookingListing"
import reserveReducers from "./reservation"
import dashBoardReducers from "./dashboard"
import leaveReducers from "./leavelist"
import maintenanceReducers from "./maintenance"
import userAdminDetailsReducers from "./userAdmin"
import unregisteredUserReducer from "./unregisteredUsers"
import allowedNavigationReducers from "./allowedNavigations"

const rootReducer = combineReducers({
  calendar: calenderReducer,
  emailApp: emailReducer,
  todoApp: todoReducer,
  chatApp: chatReducer,
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  dataList: dataList,
  users:userReducers,
  leaves: leaveReducers,
  roles:roleReducers,
  departments:departmentReducers,
  buildings:buildingsReducers,
  beds:bedReducers,
  amenities:amenityReducers,
  roomTypes:roomTypeReducers,
  roomNUmbers:roomNumberReducers,
  companies: companyReducers,
  category: categoryReducers,
  tripcosts: tripcostsReducers,
  addonsCategory:addonsCategoryReducers,
  agentAddonsCategory:agentAddonsCategoryReducers,
  addonsSubCategory:addonsSubCategoryReducers,
  vehicles:vehicleReducers,
  meals: mealsReducers,
  locations: locationsReducers,
  facilities:facilityReducers,
  divingCourses:divingCourseReducers,
  divingDepartments:divingDepartmentReducers,
  locations: locationsReducers,
  addOns:addOnReducers,
  restaurants: resturantsReducers,
  surcharges:surchargeReducers,
  taxes: taxesReducers,
  package: packageReducers,
  travelAgents:travelAgentsDetailsReducers,
  registrations:travelAgentsRegistrationDetailsReducers,
  currency:currencyReducers,
  additionalCharges:additionalChargeReducers,
  currencies:currencyReducers,
  bookingEnquiries:bookingEnquiryReducers,
  bookingSettings:bookingSettingReducers,
  bookingLists:bookingListingReducers,
  reservation: reserveReducers,
  dashBoardInfo: dashBoardReducers,
  maintenanceInfo: maintenanceReducers,
  userAdminList: userAdminDetailsReducers,
  unregisteredUser: unregisteredUserReducer,
  allowedNavigations: allowedNavigationReducers,
})

export default rootReducer
