import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_LOCATIONS_DETAILS, 
            ADD_LOCATIONS_DETAILS,
            UPDATE_LOCATIONS_DETAILS,
            DELETE_LOCATIONS_DETAILS } = URLS;
    return {
        getLocationsDetails: (body={}, method='get') =>
         performRequest( method ? method:'post', GET_LOCATIONS_DETAILS, body ),
        addLocationsDetails: (body={}, method='post') =>
         performRequest( method ? method:'post', ADD_LOCATIONS_DETAILS, body ),
        updateLocationsDetails: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_LOCATIONS_DETAILS, body ),
        deleteLocationsDetails: (body={}, method='delete') =>
         performRequest( method ? method:'delete', DELETE_LOCATIONS_DETAILS, body )

    }
}