import { performRequest } from "./api-handler";
import { URLS } from "../utility/urls";

export default () => {
  const {
    GET_PERMISSION_SETTINGS,
    GET_PERMISSION_SETTINGS_BYID,
    ADD_PERMISSION_SETTINGS,
    UPDATE_PERMISSION_SETTINGS,
    DELETE_PERMISSION_SETTINGS,
    GET_PERMISSION_BY_ROLE_DEPARTMENT,
  } = URLS;
  return {
    getPermissionSetings: (body = {}, method = "get") =>
      performRequest(method ? method : "get", GET_PERMISSION_SETTINGS, body),
    getPermissionSettingsByid: (body = {}, method = "get") =>
      performRequest(
        method ? method : "get",
        GET_PERMISSION_SETTINGS_BYID.replace(":_id", body)
      ),
    addPermisssionSetings: (body = {}, method = "post") =>
      performRequest(method ? method : "post", ADD_PERMISSION_SETTINGS, body),
    updatePermisssionSetings: (body = {}, method = "put") =>
      performRequest(
        method ? method : "post",
        UPDATE_PERMISSION_SETTINGS.replace(":_id", body._id),
        body
      ),
    deletePermisssionSetings: (body = {}, method = "delete") =>
      performRequest(
        method ? method : "post",
        DELETE_PERMISSION_SETTINGS.replace(":_id", body.id),
        body
      ),
    getPermissionByRoleDepartment: (body={}, method='get') => 
      performRequest( method ? method:'get', `${GET_PERMISSION_BY_ROLE_DEPARTMENT}/${body.role}/${body.department}`),
  };
};
