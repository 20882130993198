import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { 
        UPDATE_PRICE
     } = URLS;
    return {
        updatePrice: (body = {}, method = 'post', query) =>
            performRequest(method ? method : 'post', UPDATE_PRICE.replace(':_id', body.id), body),
    }
}




