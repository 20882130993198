import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_UNREGISTERED_USERS } = URLS;
    return {
        getUserDetails: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_UNREGISTERED_USERS, body ),
    }
}




