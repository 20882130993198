import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_OR_ADD_CATEGORY_DETAILS,
        GET_BY_CATEGORY,
        UPDATE_OR_DELETE_CATEGORY_DETAILS,
    } = URLS;
    return {
        getCategoryDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_OR_ADD_CATEGORY_DETAILS, body),
        getByCategoryDetails: (body = {}, method = 'get', _id) =>
            performRequest(method ? method : 'get', GET_BY_CATEGORY.replace(':_id', _id), body),
        addCategoryDetails: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_OR_ADD_CATEGORY_DETAILS, body),
        updateCategoryDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_OR_DELETE_CATEGORY_DETAILS.replace(':_id', body._id), body),
        deleteCategoryDetails: (body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', UPDATE_OR_DELETE_CATEGORY_DETAILS.replace(':_id', body._id), body),
       
    }}