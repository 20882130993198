import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_VEHICLE_DETAILS, 
            ADD_VEHICLE_DETAILS,
            UPDATE_VEHICLE_DETAILS,
            DELETE_VEHICLE_DETAILS,
            GET_LAND_VEHICLE_DETAILS,
            GET_BOAT_VEHICLE_DETAILS,
            GET_DRIVERS_DETAILS,
            GET_USER_DETAILS,
            GET_LOCATION,
        ADD_PASSANGERS } = URLS;
    return {
        getVehicleDetails: (body={}, method='get') =>
         performRequest( method ? method:'post', GET_VEHICLE_DETAILS, body ),
        addVehicleDetails: (body={}, method='post') =>
         performRequest( method ? method:'post', ADD_VEHICLE_DETAILS, body ),
        updateVehicleDetails: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_VEHICLE_DETAILS, body ),
        deleteVehicleDetails: (body={}, method='delete') =>
         performRequest( method ? method:'delete', DELETE_VEHICLE_DETAILS, body ),
        getLandVehicleDetails: (body={}, method='get',queryParams) =>
         performRequest( method ? method:'post', GET_LAND_VEHICLE_DETAILS+queryParams, body ),
        getBoatVehicleDetails: (body={}, method='get',queryParams) =>
         performRequest( method ? method:'post', GET_BOAT_VEHICLE_DETAILS+queryParams, body ),
        getDriversDetails: (body={}, method='get',queryParams) =>
         performRequest( method ? method:'post', GET_DRIVERS_DETAILS+queryParams, body ),
        getUserDetails: (body={}, method='get',qeury) =>
         performRequest( method ? method:'get', GET_USER_DETAILS+qeury, body ),
        getTransLocation: (body={}, method='get',qeury) =>
         performRequest( method ? method:'get', GET_LOCATION+qeury, body ),
        addPassangers: (body={}, method='post',date) =>
         performRequest( method ? method:'post', ADD_PASSANGERS.replace(":date", date), body )
    }
}