import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { RESORT_OPERATING_TIME,
        RESORT_OPERATING_TIME_WITH_ID
    } = URLS;
    return {
        getOperatingDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', RESORT_OPERATING_TIME, body),
        addNewOperatingDetails: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', RESORT_OPERATING_TIME, body),
        updateOperatingDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', RESORT_OPERATING_TIME_WITH_ID.replace(':_id', body._id), body),
        deleteOperatingDetails: (body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', RESORT_OPERATING_TIME_WITH_ID.replace(':_id', body._id), body)

    }
}