import { performRequest } from "./api-handler";
import { URLS } from "../utility/urls";

export default () => {
  const { ADD_RESORT_DETAIL, GET_RESORT_DETAIL, UPDATE_RESORT_DETAIL } = URLS;
  return {
    addResortDetails: (body = {}, method = "post") =>
      performRequest(method ? method : "post", ADD_RESORT_DETAIL, body),
    getResortDetails: (body = {}, method = "get") =>
      performRequest(method ? method : "get", GET_RESORT_DETAIL, body),
    updateResortDetails: (body = {}, method = "put", id) =>
      performRequest(method ? method : "put", UPDATE_RESORT_DETAIL.replace(":_id", id), body),
  };
};
