import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_DRIVERDEPARTMENT_SETTING_DETAILS, ADD_DRIVERDEPARTMENT_SETTING_DETAILS, GET_DRIVERS,UPDATE_DRIVERDEPARTMENT_DETAILS,GET_ASSIGNED_DRIVERS} = URLS;
    return {
        getDriverDepartmentDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_DRIVERDEPARTMENT_SETTING_DETAILS, body),
        addDriverDepartmentDetails: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_DRIVERDEPARTMENT_SETTING_DETAILS, body),
        getUsers: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_DRIVERS, body),
        updateDriverDepartmentDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'get', UPDATE_DRIVERDEPARTMENT_DETAILS, body),
        getDrivers: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_ASSIGNED_DRIVERS, body),
    }
}




