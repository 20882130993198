import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_DIVINGCOURSE_DETAILS, ADD_DIVINGCOURSE_DETAILS, UPDATE_DIVINGCOURSE_DETAILS, DELETE_DIVINGCOURSE_DETAILS, GET_PACKAGE_OPTIONS} = URLS;
    return {
        getDivingCourseDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_DIVINGCOURSE_DETAILS, body),
        addDivingCourseDetails: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_DIVINGCOURSE_DETAILS, body),
        updateDivingCourseDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_DIVINGCOURSE_DETAILS, body),
        deleteDivingCourseDetails: (body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', DELETE_DIVINGCOURSE_DETAILS, body),
        getPackageOptions: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_PACKAGE_OPTIONS, body)
    }
}




