import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_TOUR_PACKAGE_DETAILS, 
           
         } = URLS;
    return {
        getTourPackageDetails: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_TOUR_PACKAGE_DETAILS, body ),       
    }
}