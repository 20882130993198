import * as Types from '../../actions/type'
const initialState = {
    loading: false,
    loaded: false,
    paymentReminder: [],
    expiredInvoice: [],
    currentYear: [],
    prevYear:[],
    currentYearCheck: [],
    prevYearCheck:[],
    payAtResort: [],
    todayPickUp: [],
    currentInfo: [],
    arrivalInfo: [],
    deptInfo: [],
    cardInfoType: [],
    currentRoom: [],
    roomTypes: {},
    geustCount: {},
    unconfirmPay: [],
    currentCounts: {},
    arrivalCounts: {},
    deptCounts: {},
    endDayCounts: {},
    currentRoomCheckIn: [],
    currentRoomCheckOut: []
}



export const dashBoardDetails = ( state=initialState, action) =>{
    switch (action.type){
        case Types.DASHBOARD_LOADING:
            return { ...state, loading: true };
        case Types.DASHBOARD_FAILURE:
            return { ...state, loading: false, loaded: false, error: action.payload }
        case Types.GET_PAYMENT_REMINDER:
            return { ...state, loading: false,paymentReminder: action.payload.data };
        case Types.GET_DASHBOARD_PAYATRESORT:
             return { ...state, loading: false,payAtResort: action.payload.data };
        case Types.GET_DASHBOARD_TODAYPICKUP:
             return { ...state, loading: false,todayPickUp: action.payload.data };
        case Types.GET_EXPIRED_INVOICE:
             return { ...state, loading: false,expiredInvoice: action.payload.data };
        case Types.GET_DASHBOARD_CARDINFO_TYPE:
             return { ...state, loading: false,cardInfoType: action.payload.data };
        case Types.GET_DASHBOARD_UNCONFIRMED_PAY:
             return { ...state, loading: false,unconfirmPay: action.payload.data };
        case Types.GET_DASHBOARD_CURRENT_LIST:
             return { ...state, loading: false, roomTypes: action.payload.data.roomTypes,geustCount: action.payload.data.count, currentRoom: action.payload.data.data };
        case Types.GET_DASHBOARD_CURRENT_LIST_CHECKIN:
               return { ...state, loading: false, currentRoomCheckIn: action.payload.data.arrival };
        case Types.GET_DASHBOARD_CURRENT_LIST_CHECKOUT:
             return { ...state, loading: false, currentRoomCheckOut: action.payload.data.dept };
        case Types.GET_DASHBOARD_CARDINFO:
             return { ...state, loading: false, currentInfo: action.payload.data.current,arrivalInfo: action.payload.data.arrival, deptInfo: action.payload.data.dept, currentCounts:action.payload.data.currentCounts,arrivalCounts: action.payload.data.arrivalCounts, deptCounts: action.payload.data.deptCounts, endDayCounts: action.payload.data.endDayCounts };            
        case Types.GET_DASHBOARD_GRAPH:
             return { ...state, loading: false, currentYear: action.payload.data.currentYear, prevYear: action.payload.data.prevYear};
        case Types.GET_DASHBOARD_GRAPH_CHECKIN:
             return { ...state, loading: false, currentYearCheck: action.payload.data.currentYear, prevYearCheck: action.payload.data.prevYear};                       
        default: 
          return state
    }
}



