import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_PROMOCODE_DETAILS,
        ADD_PROMOCODE_DETAILS,
        UPDATE_PROMOCODE_DETAILS,
        DELETE_PROMOCODE_DETAILS,
        GET_PROMO_PACKAGE,
        GET_PROMO_PACKAGE_FILTER,
        BUY_PROMO_PACKAGE,
        PROMO_OFFLINE_PAYMENT,
        PROMO_APPROVE_PAYMENT,
        GET_BULK_PAYMENT,
        BULK_APPROVE_PAYMENT,
        BUY_KNOK_PAY,
        BULK_OFFLINE_PAYMENT,
        BULK_RESERVATION_PAY,
        UPDATE_SPLIT_AMOUNT,
        GET_PROMO_VOCHER_CODE,
        UPDATE_BULK_PAYMENT
    } = URLS;
    return {
        getPromoCodeDetails: (body = {}, method = 'get', url) =>
            performRequest(method ? method : 'get', url || GET_PROMOCODE_DETAILS, body),
        getBulkReservation: (body = {}, method = 'get',params) =>
            performRequest(method ? method : 'get', BULK_RESERVATION_PAY+params, body),
        addPromoCodeDetails: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_PROMOCODE_DETAILS, body),
        getPromoDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', UPDATE_PROMOCODE_DETAILS.replace(":_id", body._id), body),
        updatePromoDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_PROMOCODE_DETAILS, body),
       updateSplitDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_SPLIT_AMOUNT.replace(":_id", body._id), body),
        deletePromoDetails: (body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', DELETE_PROMOCODE_DETAILS, body),
        getPromoPackages: (body = {}, method = 'get', url) =>
            performRequest(method ? method : 'get', url || GET_PROMO_PACKAGE, body),
        getPromoPackFilt: (body = {}, method = 'get', url) =>
            performRequest(method ? method : 'get', GET_PROMO_PACKAGE_FILTER, body),
        buyPromoPackage: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', BUY_PROMO_PACKAGE, body),
        buyKnokPay: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', BUY_KNOK_PAY, body),
        saveOfflinePayment: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post',
                PROMO_OFFLINE_PAYMENT.replace(":_id", body._id), body),
        saveBulkOfflinePayment: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post',
                BULK_OFFLINE_PAYMENT.replace(":_id", body._id), body),
        promoApprovePayment: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put',
                PROMO_APPROVE_PAYMENT.replace(":_id", body._id).replace(":paymentId", body.paymentId), body, "", "", "", "admin"),
        getBulkPayment: (body = {}, method = 'get', url) =>
            performRequest(method ? method : 'get', url || GET_BULK_PAYMENT, body),
        bulkApprovePayment: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put',
                BULK_APPROVE_PAYMENT.replace(":_id", body._id).replace(":paymentId", body.paymentId), body, "", "", "", "admin"),
        getVocherCode: (body = {}, method = 'get', type) =>
                performRequest(method ? method : 'get', GET_PROMO_VOCHER_CODE+type, body), 
        updateBulkDetails: (body = {}, method = 'put') =>
                performRequest(method ? method : 'put', UPDATE_BULK_PAYMENT.replace(":_id", body._id), body),

                UPDATE_BULK_PAYMENT

    }
}