import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_COMPANIES_DETAILS, 
            ADD_COMPANIES_DETAILS,
            UPDATE_COMPANIES_DETAILS,
            DELETE_COMPANIES_DETAILS,
            GET_BOAT_QTY, 
            GET_ACCOUNTS_COMPANIES_DETAILS } = URLS;
    return {
        getCompanyDetails: (body={}, method='get') =>
         performRequest( method ? method:'post', GET_COMPANIES_DETAILS, body ),
        addCompanyDetails: (body={}, method='post') =>
         performRequest( method ? method:'post', ADD_COMPANIES_DETAILS, body ),
        updateCompanyDetails: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_COMPANIES_DETAILS, body ),
        deleteCompanyDetails: (body={}, method='delete') =>
         performRequest( method ? method:'delete', DELETE_COMPANIES_DETAILS, body ),
        getBoatQty: (body={}, method='get') =>
            performRequest( method ? method:'get', GET_BOAT_QTY, body ),
        getAccountsCompanyDetails: (body={}, method='get') =>
            performRequest( method ? method:'get', GET_ACCOUNTS_COMPANIES_DETAILS, body ),
    }
}