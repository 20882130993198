import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_MEALS_DETAILS, 
            ADD_MEALS_DETAILS,
            UPDATE_MEALS_DETAILS,
            DELETE_MEALS_DETAILS,
            GET_RESTAURANTS_DETAILS } = URLS;
    return {
        getMealDetails: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_MEALS_DETAILS, body ),
        addMealsDetails: (body={}, method='post') =>
         performRequest( method ? method:'post', ADD_MEALS_DETAILS, body ),
        updateMealsDetails: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_MEALS_DETAILS, body ),
        deleteMealsDetails: (body={}, method='delete') =>
         performRequest( method ? method:'delete', DELETE_MEALS_DETAILS, body ),
        getRestaurantsDetails: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_RESTAURANTS_DETAILS, body ),

    }
}