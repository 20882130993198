import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_TRAVELAGENT_DETAILS,ADD_TRAVELAGENT_DETAILS,UPDATE_TRAVELAGENT_DETAILS,DELETE_TRAVELAGENT_DETAILS, RESET_LOGIN } = URLS;
    return {
        getTravelAgentDetails: (body={}, method='get') =>
         performRequest( method ? method:'post', GET_TRAVELAGENT_DETAILS, body ),
        addTravelAgentDetails: (body={}, method='post') =>
         performRequest( method ? method:'post', ADD_TRAVELAGENT_DETAILS, body ),
        updateTravelAgentDetails: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_TRAVELAGENT_DETAILS, body ),
        deleteTravelAgentDetails: (body={}, method='delete') =>
         performRequest( method ? method:'delete', DELETE_TRAVELAGENT_DETAILS, body ),
        resetLogin: (body={}, method='put', _id) =>
            performRequest( method ? method:'put', RESET_LOGIN, body,null, _id ),
    }
}




