import * as Types from '../../actions/type'

const initialState = {
  isError: false,
  allowedNavigations: [],
  loading: true,
}

export const allowedNavigationDetails = (state = initialState, action) => {
  switch (action.type) {
    case Types.ADD_ALLOWED_NAVIGATION:
      return {...state, allowedNavigations: action.payload}
    case Types.GET_ALLOWED_NAVIGATION_ERROR:
      return {...state, isError: true}
    case Types.FULFILLED_ALLOWED_NAVIGATION:
      return {...state, loading: false}
    default:
      return state
  }
}