import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_BOOKING_ENQUIRY_DETAILS,
        ADD_BOOKING_ENQUIRY_DETAILS,
        UPDATE_BOOKING_ENQUIRY_DETAILS,
        DELETE_BOOKING_ENQUIRY_DETAILS,
        UPDATE_BOOKING_ENQUIRY_STATUS,
    } = URLS;
    return {
        getBookingEnquiryDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_BOOKING_ENQUIRY_DETAILS, body),
        addBookingEnquiryDetails: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_BOOKING_ENQUIRY_DETAILS, body),
        updateBookingEnquiryDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_BOOKING_ENQUIRY_DETAILS, body),
        deleteBookingEnquiryDetails: (body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', DELETE_BOOKING_ENQUIRY_DETAILS, body),
        updateBookingEnquiryStatus: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_BOOKING_ENQUIRY_STATUS, body),
    }
}