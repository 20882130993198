import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_MAITENANCE_INFO,
        UPDATE_MAITENANCE_INFO } = URLS;
    return {
        getMaintananceDetails: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_MAITENANCE_INFO, body ),
        addMaintananceDetails: (body={}, method='post') =>
         performRequest( method ? method:'post', GET_MAITENANCE_INFO, body ),
        updateMaintananceDetails: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_MAITENANCE_INFO, body ),
        deleteMaintananceDetails: (body={}, method='delete') =>
         performRequest( method ? method:'delete', UPDATE_MAITENANCE_INFO, body )

    }
}