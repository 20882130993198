import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_REWARDS,
        GET_REWARD,
        } = URLS;
    return {
        getCustomerRewards: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_REWARDS, body),
        getCustomerReward: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_REWARD.replace(':_id',body._id), body),
        updateCustomerReward: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', GET_REWARD.replace(':_id',body._id), body),
    }
}




