import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_EVENTS_LIST, GET_EVENT_DETAILS,
        GET_EVENT_CHECKLIST, ADD_EVENT_CHECKLIST,
        UPDATE_EVENT_CHECKLIST,
        GET_EVENT_PlANLIST,
        ADD_EVENT_PLANLIST,
        DELETE_EVENT_PLANLIST,
        GET_EVENT_ROOMNUMBER,
        ADD_EVENT_MEMBERS,
        GET_EVENT_MEMBERS,
        DELETE_EVENT_MEMBERS,
        PRINT_DETAILS,
        SAVE_OFFLINE_EVENT_PAYMENT,
        UPDATE_MEMBER_CHECKEDIN,
        SEND_EVENT_INVOICE_MAIL,
     } = URLS;
    return {
        getEventsDetailDetails: (body = {}, method = 'get', query) =>
            performRequest(method ? method : 'get', GET_EVENTS_LIST + query, body),
        createEvent: (body = {}, method = 'post', query) =>
            performRequest(method ? method : 'post', GET_EVENTS_LIST, body),
        getEventInformation: (body = {}, method = 'get', id) =>
            performRequest(method ? method : 'get', GET_EVENT_DETAILS.replace(":_id", id), body),
        getEventCheckList: (body = {}, method = 'get', id, qeury) =>
            performRequest(method ? method : 'get', GET_EVENT_CHECKLIST.replace(":eventId", id) + qeury, body),
        getEventPlanList: (body = {}, method = 'get', id, qeury) =>
            performRequest(method ? method : 'get', GET_EVENT_PlANLIST.replace(":eventId", id) + qeury, body),
        addEventCheckList: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_EVENT_CHECKLIST, body),
        deleteEventCheckList: (body = {}, method = 'delete', id) =>
            performRequest(method ? method : 'delete', UPDATE_EVENT_CHECKLIST.replace(":_id", id), body),
        updateEventCheckList: (body = {}, method = 'put', id) =>
            performRequest(method ? method : 'put', UPDATE_EVENT_CHECKLIST.replace(":_id", id), body),
        updateEventInfo: (body = {}, method = 'put', id) =>
            performRequest(method ? method : 'put', GET_EVENT_DETAILS.replace(":_id", id), body),
        addEventPlanList: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_EVENT_PLANLIST, body),
        deleteEventPlanList: (body = {}, method = 'delete', id) =>
            performRequest(method ? method : 'delete', DELETE_EVENT_PLANLIST.replace(":_id", id), body),
        getEventRoomInfo: (body = {}, method = 'get', id, date) =>
            performRequest(method ? method : 'get', GET_EVENT_ROOMNUMBER.replace(":_id", id).replace(":eventDate", date), body),
        addEventMembers: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_EVENT_MEMBERS, body),
        getEventMembers: (body = {}, method = 'get', id, qeury) =>
            performRequest(method ? method : 'get', GET_EVENT_MEMBERS.replace(":eventId", id) + qeury, body),
        deleteEventMembers: (body = {}, method = 'delete', id) =>
            performRequest(method ? method : 'delete', DELETE_EVENT_MEMBERS.replace(":_id", id), body),
        getPrintTicketDetails: (body = {}, method = 'get', id) =>
            performRequest(method ? method : 'get', PRINT_DETAILS.replace(":_id", id), body),  
        saveOfflineEventPayment: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', SAVE_OFFLINE_EVENT_PAYMENT, body),  
        updateMemberCheckedIn: (body = {}, method = 'put', id) =>
            performRequest(method ? method : 'put', UPDATE_MEMBER_CHECKEDIN.replace(":memberListId", id), body),
        sendEventInvoiceMail: (body = {}, method = 'post', id) =>
            performRequest(method ? method : 'post', SEND_EVENT_INVOICE_MAIL.replace(":memberListId", id), body),
    }
}




