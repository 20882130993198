import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_BOOKING_SETTING_DETAILS,
        ADD_BOOKING_SETTING_DETAILS,
        UPDATE_BOOKING_SETTING_DETAILS,
        DELETE_BOOKING_SETTING_DETAILS
    } = URLS;
    return {
        getBookingSettingDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_BOOKING_SETTING_DETAILS, body),
        addBookingSettingDetails: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_BOOKING_SETTING_DETAILS, body),
        updateBookingSettingDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_BOOKING_SETTING_DETAILS, body),
        deleteBookingSettingDetails: (body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', DELETE_BOOKING_SETTING_DETAILS, body)

    }
}