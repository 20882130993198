import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_CURRENCY_DETAILS,ADD_CURRENCY_DETAILS,UPDATE_CURRENCY_DETAILS,DELETE_CURRENCY_DETAILS,CHANGE_STATUS_CURRENCY_DETAILS, GET_CURRENCY_SYMBOLS } = URLS;
    return {
        getCurrencyDetails: (body={}, method='get') =>
         performRequest( method ? method:'post', GET_CURRENCY_DETAILS, body ),
         getCurrencySymbolDetails: (body={}, method='get') =>
         performRequest( method ? method:'post', GET_CURRENCY_SYMBOLS, body ),
        addCurrencyDetails: (body={}, method='post') =>
         performRequest( method ? method:'post', ADD_CURRENCY_DETAILS, body ),
        updateCurrencyDetails: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_CURRENCY_DETAILS, body ),
        deleteCurrencyDetails: (body={}, method='delete') =>
         performRequest( method ? method:'delete', DELETE_CURRENCY_DETAILS, body )

    }
}




