import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_ADDITIONAL_CHARGES_DETAILS,
        ADD_ADDITIONAL_CHARGES_DETAILS,
        UPDATE_ADDITIONAL_CHARGES_DETAILS,
        DELETE_ADDITIONAL_CHARGES_DETAILS } = URLS;
    return {
        getAdditionalChargesDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_ADDITIONAL_CHARGES_DETAILS, body),
        addAdditionalChargesDetails: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_ADDITIONAL_CHARGES_DETAILS, body),
        updateAdditionalChargesDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_ADDITIONAL_CHARGES_DETAILS, body),
        deleteAdditionalChargesDetails: (body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', DELETE_ADDITIONAL_CHARGES_DETAILS, body)

    }
}




