import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_AGENT_PROFILE_DETAILS, 
            GET_AGENT_DETAILS,
            ADD_AGENT_PROFILE_DETAILS,
            UPDATE_AGENT_PROFILE_DETAILS,
            AGENT_FILE_UPLOAD,
            UPDATE_AGENT_PERSONAL_DETAILS
                      
         } = URLS;
    return {
        getAgentProfileDetails: (body={}, method='get', url) =>
         performRequest( method ? method:'get', url ||GET_AGENT_PROFILE_DETAILS, body ),
        addAgentProfileDetail: (body={}, method='post') =>
         performRequest( method ? method:'post', ADD_AGENT_PROFILE_DETAILS.replace(':_id', body?.userId), body ),
         updatAgentProfileDetail: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_AGENT_PROFILE_DETAILS, body ),
         updatAgentPersonalDetail: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_AGENT_PERSONAL_DETAILS, body ),
         uploadAgentImage: (body={}, method='post') =>
         performRequest( method ? method:'put', AGENT_FILE_UPLOAD, body ),
        getAgentData:(body={}, method='get', url) =>
        performRequest( method ? method:'get', url || GET_AGENT_DETAILS, body ),




    }


}