import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { SOCIAL_SHARE_URL
        } = URLS;
    return {
        getCustomerSocialShare: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', SOCIAL_SHARE_URL),
        postCustomerSocialShare: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', SOCIAL_SHARE_URL, body),
        updateCustomerSocialShare: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', SOCIAL_SHARE_URL, body),
    }
}




